<template>
  <section class="xl:grid xl:grid-cols-4">
    <!-- Principle Picture -->
    <div
      class="principle-picture container max-w-screen-lg mx-auto p-4 xl:col-span-1"
    >
      <p class="text-2xl text-left mb-7">Foto Kepala Sekolah</p>
      <img
        :src="imageUrl"
        alt="profile picture"
        id="profile-picture"
        class="shadow mx-auto rounded max-w-xs"
      />
      <image-uploader
        ref="imageUploader"
        text="Unggah"
        :ratio="1"
        @ready="triggerUploadImage"
      ></image-uploader>
    </div>
    <div class="mt-10 p-4 xl:col-start-2 xl:col-span-3">
      <p class="uppercase tracking-wider text-xl mb-2">Kata sambutan</p>
      <p class="uppercase tracking-widest text-xs mb-2">
        Pembaruan Terakhir:
        {{ lastUpdated | indonesian_datetime_format }}
      </p>
      <toast-editor
        class="mb-2"
        preview-style="tab"
        ref="toastuiEditor"
        initial-value=""
        :height="editor.height"
        :options="editor.options"
        @change="onEditorChange"
      ></toast-editor>
      <button class="button --primary" @click="triggerStoreOpeningSpeech()">
        <fa-icon icon="save"></fa-icon>
        <span class="ml-2">{{
          isProcessing ? "dalam proses..." : "Simpan"
        }}</span>
      </button>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.principle-picture {
  min-height: 100px;

  @apply mt-10 relative text-center;
}
</style>

<script>
import "codemirror/lib/codemirror.css";
import "@toast-ui/editor/dist/toastui-editor.css";

import { Editor } from "@toast-ui/vue-editor";
import { mapActions, mapGetters } from "vuex";
import _debounce from "lodash/debounce";
import _clone from "lodash/clone";

export default {
  data() {
    return {
      lastUpdated: null,
      imageUrl: null,
      isProcessing: false,
      form: {
        content: null,
      },
      formDefault: {},
      editor: {
        options: {
          usageStatistics: false,
          hideModeSwitch: true,
        },
        height: "500px",
      },
    };
  },
  methods: {
    /**
     * Markdown's
     */
    getContent() {
      return this.$refs.toastuiEditor.invoke("getMarkdown");
    },
    setContent(content) {
      return this.$refs.toastuiEditor.invoke("setMarkdown", content);
    },
    onEditorChange: _debounce(
      function () {
        this.form.content = this.getContent();
      },
      700,
      { maxWait: 1000 }
    ),
    async triggerStoreOpeningSpeech() {
      if (this.isProcessing) {
        return false;
      }
      this.isProcessing = true;
      await this.storeOpeningSpeech(this.form);
      this.isProcessing = false;
      this.lastUpdated = this.opening_speech.updated_at;
    },

    /**
     * Image upload
     */
    async triggerUploadImage(file) {
      const formData = new FormData();
      formData.append("image", file);
      await this.uploadProfilPicture(formData);

      // reload image
      const reader = new FileReader();
      reader.onload = function (e) {
        let imageContainer = document.getElementById("profile-picture");
        imageContainer.src = e.target.result;
      };
      reader.readAsDataURL(file);
    },

    resetForm() {
      this.form = _clone(this.formDefault);
    },

    ...mapActions("opening_speech", [
      "fetchOpeningSpeech",
      "storeOpeningSpeech",
      "uploadProfilPicture",
    ]),
  },
  computed: {
    ...mapGetters("opening_speech", ["opening_speech"]),
  },
  async created() {
    await this.fetchOpeningSpeech();
    this.setContent(this.opening_speech.content);
    this.lastUpdated = this.opening_speech.updated_at;
    this.imageUrl = this.opening_speech.full_image_url;
  },
  beforeDestroy() {
    this.$refs.toastuiEditor.invoke("destroy");
  },
  components: {
    toastEditor: Editor,
    ImageUploader: () => import("@/components/Common/ImageUploader.vue"),
  },
  name: "OpeningSpeech",
};
</script>